import { Button } from 'antd';
import Router, { useRouter } from 'next/router';

const Custom404 = () => {
  const router = useRouter();
  return (
    <div id='not-found'>
      <div className='main-content'>
        <div className='container'>
          <div className='inner'>
            <div className='image'>
              <img src='/assets/images/404.png' alt='' />
            </div>
            <h3>ページが見つかりません</h3>
            <a href='/' className='btn-back'> ホームページ へ戻る</a>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Custom404;